

/* For Dark Mode */
.rdrDateRangeWrapper.dark-mode {
  background-color: #28353e; /* Background */
  color: #fff; /* Light text */
}

.rdrDateRangeWrapper.dark-mode .rdrDateDisplayWrapper  {
  background-color: #2a3a4a; /* Secondary Colour */
}

/* .rdrDateRangeWrapper.dark-mode .rdrMonthAndYearPickers select {
  color: #fff;
} */

.rdrDateRangeWrapper.dark-mode .rdrMonthAndYearPickers select{
  color: #ffffff;
  background: transparent;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%23000000' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
  background-position: right 8px center;
  background-color: #28353e !important; /* Background */
}

.rdrDateRangeWrapper.dark-mode .rdrDayNumber span {
  color: #fff; /* Light text */
}

.rdrDateRangeWrapper.dark-mode .rdrDateDisplayItem{
  background-color: #28353e; /* Background */
}

.rdrDateRangeWrapper.dark-mode .rdrNextPrevButton {
  background-color: #2a3a4a; /* Secondary Colour */
}

.rdrDateRangeWrapper.dark-mode .rdrPprevButton i {
  border-color: transparent #979797 transparent transparent;
}
.rdrDateRangeWrapper.dark-mode .rdrNextButton i {
  border-color: transparent transparent transparent #979797
}

.rdrDateRangeWrapper.dark-mode .rdrDateDisplayItemActive input {
  color: #ffffff;
}

.rdrDateRangeWrapper.dark-mode .rdrDayPassive .rdrDayNumber span{
  color: #959798;
}

.rdrDateRangeWrapper.dark-mode .rdrSelected,
.rdrDateRangeWrapper.dark-mode .rdrInRange,
.rdrDateRangeWrapper.dark-mode .rdrStartEdge,
.rdrDateRangeWrapper.dark-mode .rdrEndEdge,
.rdrDateRangeWrapper.dark-mode .rdrDayToday .rdrDayNumber span:after
{
  background-color: #ecb24e; /* Primary Colour */
  color: #fff; /* Light text */
}

.rdrDateRangeWrapper.dark-mode .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDateRangeWrapper.dark-mode .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after {
  background-color: #fff;
}

/* You may need to override more classes depending on the look you are going for */
